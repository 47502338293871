import React, { Component } from 'react'
import Link from 'gatsby-link'
import Footer from '../components/Footer/Footer'
import Iframe from '../components/Iframe/Iframe'
import Helmet from 'react-helmet'

import classes from '../layouts/Pages.module.css'

export default class extends Component {

    render() {
        const { gDeviceWidth: deviceWidth } = this.props
        return (
            <div>
                <Helmet>
                    <title>Contactez le Psy-Scan Institute | 058 201 06 11</title>
                    <meta name="description" content="Nos consultations se déroulent du Lundi au Vendredi de 9h à 12h et 13h à 17h. Vous pouvez nous appelez ou réserver votre consultation en ligne."/>
                </Helmet>
                <div>
                    <div style={{maxWidth: 1170, padding: '20px', margin: 'auto'}}>
                        <div className={classes.Address}>
                            <div className={classes.AddressBock} style={{display: 'flex', alignItems: 'center', padding: 0}}>
                                <div>
                                    <h1>Nous joindre</h1>
                                    <div>
                                        <div><strong>Psy-Scan Institute</strong></div>
                                        <div style={{color: '#1689DF'}}>
                                            <div>Lundi - Vendredi: </div>
                                            <div>9AM - 12PM & 1PM - 5PM</div>
                                        </div>

                                        Grand-Rue 1A <br />
                                        2000 Neuchatel<br />
                                        Tél. 058 201 06 11<br />
                                        Fax. 058 201 06 11<br />
                                        E-mail: <span><a href="mailto:admin@psy-scan.ch">admin@psy-scan.ch</a></span><br />
                                        <Link to="/reservation"><em><strong>ou Prenez rendez en ligne</strong></em></Link>
                                    </div>
                                </div>

                            </div>
                            <div className={classes.AddressBockMap}>
                                <div>
                                    <Iframe width="100%" height={`${deviceWidth > 767 ? "400px" : '300px' }`} src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyD5d58sVD8JymoPG0mWxmQP8glK9_m9cI4&q=Psi+:+Psy-Scan+Institute+Sàrl'}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.Cancellation}>
                            <div className={classes.CancellationItem}>
                                <h2>Annuler un rendez-vous</h2>

                                <p>
                                    Si vous annulez ou re-schedulez votre premier rendez-vous avec un préavis de moins de 48 heures,
                                    ou si vous ne vous présentez pas, vous serez facturé le montant total de la consultation.
                                </p>
                            </div>
                            <div className={classes.CancellationItem}>
                                <h2>Remboursement</h2>
                                <p>
                                    Toutes les thérapies au Psy-Scan Institute sont remboursées par votre assurance maladie de base.
                                    Pour plus d'information veuillez prendre contact avec le secrétariat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>

            </div>
        )
    }
}

